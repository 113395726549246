import { Center, Container, Heading, VStack } from "@chakra-ui/react";
import MuxPlayer from "@mux/mux-player-react";
import React from "react";
import Layout from "../components/Layout";

const Videos = () => {
  return (
    <Layout>
      <Container>
        <VStack>
          <Heading>Videos</Heading>
          <MuxPlayer
            playbackId="WJ2hoGykQ501pHi3F02U9LV45j9qElCWBnLkYninGGqnE"
            metadata={{
              video_id: "wedding RAW",
              video_title: "RAW video",
              viewer_user_id: "wedding website",
            }}
            streamType="on-demand"
          />
        </VStack>
      </Container>
    </Layout>
  );
};

export default Videos;
